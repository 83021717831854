import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const subCategorySlice=createSlice({
    name: "subCategory",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setsubCategory(state,action){
            state.data=action.payload
        },
        getsubCategory(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setsubCategory,setStatus,getsubCategory}=subCategorySlice.actions
export default subCategorySlice.reducer




// thunk

export function fetchsubCategory(){
    return async function fetchsubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`http://5.78.109.151:5000/common/subCategory/getAllSubCategories`).then(
                (resp) => {
                    console.log(resp.data)
                    dispatch(setsubCategory(resp?.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createsubCategory(param){
    console.log(param)
    console.log('working')
    return async function createsubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try {
            const response = await axios.post('http://5.78.109.151:5000/common/subCategory/createSubCategories', param, {
                headers: {
                    'Content-Type': 'application/json' 
                },
                
            });
            console.log(response.data)
            if (response.data) {
               await message.success("Subcategory Created")
                 window.location.reload(true)
                        }
            console.log(response.data)
            
        } catch (error) {
            
            console.log(error);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatesubCategory(param){
    return async function updatesubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        const headers = {
            'Content-Type': 'application/json', // Assuming your data is JSON
            // Add any other headers here
        };
        try{
            await axios.patch(`http://5.78.109.151:5000/common/subCategory/updateSubCategories/${param.id}`,{name:param.name, categoryId: param.categoryId,image:param.image},{headers}).then(res=>{
                console.log(res.data)
                if (res.data) {
                     message.success("subCategory Updated") 

                    window.location.href=window.location.origin+"/#/subCategory"
                    window.location.reload(true)
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            message.error('Something went wrong')
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}

export function updatesubCategoryStatus(param){
    return async function updatesubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        const headers = {
            'Content-Type': 'application/json', // Assuming your data is JSON
            // Add any other headers here
        };
        try{
            await axios.patch(`http://5.78.109.151:5000/common/subCategory/updateSubCategories/${param.id}`,{status:param.status},{headers}).then(res=>{
                console.log(res.data)
                if (res.data) {
                  message.success("subCategory Updated") 
                    window.location.href=window.location.origin+"/#/subCategory"
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            message.error('Something went wrong')
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function deletesubCategory(id){
    return async function deletesubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`http://5.78.109.151:5000/common/subCategory/delSubCategories/${id}`).then(res=>{
                console.log(res.data)
                if(res.data){
                  window.location.reload(true)
                  message.success('Sub category deleted')
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            message.error('Something went wrong')
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdsubCategory(id){
    return async function getByIdsubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`http://5.78.109.151:5000/common/subCategory/getSubCategories/${id}`).then(
             
                (resp) => {
                    dispatch(getsubCategory(resp.data))
                    console.log(resp.data)
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
