import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const allOrderSlice=createSlice({
    name: "allOrder",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setallOrder(state,action){
            state.data=action.payload
        },
        getallOrder(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setallOrder,setStatus,getallOrder}=allOrderSlice.actions
export default allOrderSlice.reducer




// thunk

export function fetchSubscription(){
    return async function fetchallOrderThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`http://5.78.109.151:5000/common/subscription/getAllSubscriptions`).then(
               
                (resp) => {
                    console.log(resp.data)
                    dispatch(setallOrder(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createSubscription(param){
    return async function createallOrderThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        console.log(param)
        try {
            const response = await axios.post('http://5.78.109.151:5000/common/subscription/createSubscriptions', param, {
                headers: {
                    'Content-Type': 'application/json' 
                },
                
            });
            console.log(response.data)
            if (response.data) {
               await message.success("Subscription  Created")
                 window.location.reload(true)
                        }
            console.log(response.data)
            
        } catch (error) {
            
            console.log(error.response.data);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateSubscription(id,param){
    console.log(id,param)
    return async function updateallOrderThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.patch(`http://5.78.109.151:5000/common/subscription/updateSubscriptions/${id}`,param,
                {
                    headers: {
                        'Content-Type': 'application/json' 
                    },
                    
                }
            ).then(res=>{
                console.log(res.data)
                if (res.data) {
                    message.success("Subscription Update")
                    window.location.href=window.location.origin+"/#/subscription"                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletesubscription(id){
    return async function deleteallOrderThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`http://5.78.109.151:5000/common/subscription/delSubscriptions/${id}`).then(res=>{
                if(res.data){
                  window.location.reload(true)
                  message.success('Subscription deleted')
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdallOrder(id){
    console.log(id)
    return async function getByIdallOrderThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`http://5.78.109.151:5000/common/subscription/getSubscriptions/${id}`).then(
                (resp) => {
                    console.log(resp.data)
                    dispatch(getallOrder(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
