import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import { deleteBanner } from '../../store/bannerSlice';
import { fetchBannerfalse } from './../../store/bannerSlice';
import { useNavigate } from 'react-router-dom';
import { updatebussinessstatus } from '../../store/categorySlice';

const Banners = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [checkedStates, setCheckedStates] = useState([]);
    const { data: banner, status } = useSelector((state) => state.banner);
    const [filteredProduct, setFilteredProduct] = useState([]);

    useEffect(() => {
        dispatch(fetchBannerfalse());
    }, []);

    useEffect(() => {
        if (banner.length > 0) {
            const initialCheckedStates = banner.map(item => item.status); // Assuming `status` is a boolean
            setCheckedStates(initialCheckedStates);
        }

        // Initialize DataTable only when there is data
        if (banner.length > 0) {
            setTimeout(function () {
                $('#allOrder').DataTable({
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    destroy: true,
                });
            }, 1000);
        }
    }, [banner]);

    const toggleSwitch = async (index, categoryId, currentStatus) => {
        const newStatus = !checkedStates[index]; // Toggle the current state

        try {
            // Update UI optimistically
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = newStatus;
                return newStates;
            });

            // Update backend via Redux action
            await dispatch(updatebussinessstatus({ id: categoryId, status: newStatus }));
        } catch (error) {
            // Handle error (rollback UI changes if needed)
            console.error('Error updating status:', error);

            // Revert UI changes on failure
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = currentStatus; // Revert to previous status
                return newStates;
            });

            // Optionally show error message
            message.error('Failed to update category status.');
        }
    };

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteBanner(id));
            },
        });
    };

    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2">
                        Business
                    </h2>
                </div>
                <div className="row" id="banner-table">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="px-3 py-4">
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-lg-6 mb-2 mb-md-0">
                                        <h5 className="mb-0 text-capitalize d-flex gap-2">
                                            Business
                                            <span className="badge badge-soft-dark radius-50 fz-12">{banner.length}</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table
                                    id="allOrder"
                                    style={{ textAlign: "left" }}
                                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                >
                                    <thead className="thead-light thead-50 text-capitalize">
                                        <tr>
                                            <th className="pl-xl-5">SL</th>
                                            <th>Name</th>
                                            <th>Logo</th>
                                            <th>Discount</th>
                                            <th>Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {banner.length > 0 ? banner.map((i, index) => (
                                            <tr key={i._id}>
                                                <td className="pl-xl-5">{index + 1}</td>
                                                <td>{i.companyName}</td>
                                                <td>
                                                    <img
                                                        className="ratio-4:1"
                                                        width={80}
                                                        src={i.companyLogo}
                                                        alt={i.companyName}
                                                    />
                                                </td>
                                                <td>{i.discountOfferingPercentage}</td>
                                                <td className="text-center">
                                                    <div style={{
                                                        position: 'relative',
                                                        display: 'inline-block',
                                                        width: '40px',
                                                        height: '20px',
                                                    }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={checkedStates[index]}
                                                            onChange={() => toggleSwitch(index, i._id, i.status)}
                                                            style={{
                                                                opacity: 0,
                                                                width: 0,
                                                                height: 0,
                                                            }}
                                                        />
                                                        <div
                                                            onClick={() => toggleSwitch(index, i._id, i.status)}
                                                            style={{
                                                                position: 'absolute',
                                                                cursor: 'pointer',
                                                                top: 0,
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                backgroundColor: checkedStates[index] ? '#2196F3' : '#ccc',
                                                                transition: '.4s',
                                                                borderRadius: '20px',
                                                            }}
                                                        >
                                                            <div style={{
                                                                position: 'absolute',
                                                                content: '""',
                                                                height: '16px',
                                                                width: '16px',
                                                                left: checkedStates[index] ? '22px' : '2px',
                                                                bottom: '2px',
                                                                backgroundColor: 'white',
                                                                transition: '.4s',
                                                                borderRadius: '50%',
                                                            }}></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-center gap-2">
                                                        <button
                                                            className="btn btn-outline-danger square-btn btn-sm mr-1"
                                                            onClick={() => onDelete(i._id)}
                                                            title="Delete"
                                                        >
                                                            <i className="bi bi-trash" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : <tr><td colSpan="6" className="text-center">No data available</td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banners;
