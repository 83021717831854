import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const subSubCategorySlice=createSlice({
    name: "subSubCategory",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setsubSubCategory(state,action){
            state.data=action.payload
        },
        getsubSubCategory(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setsubSubCategory,setStatus,getsubSubCategory}=subSubCategorySlice.actions
export default subSubCategorySlice.reducer




// thunk

export function fetchsubSubCategory(){
    return async function fetchsubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`http://5.78.109.151:5000/common/subSubCategory/getAllSubSubCategories`).then(
                (resp) => {
                    console.log(resp.data)
                    dispatch(setsubSubCategory(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}


// 

export function createsubSubCategory(param){
    console.log(param)
    return async function createsubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try {
            const response = await axios.post('http://5.78.109.151:5000/common/subSubCategory/createSubSubCategories', param, {
                headers: {
                    'Content-Type': 'application/json' 
                },
                
            });
            console.log(response.data)
            if (response.data) {
             await message.success("Sub sub category Created")
                 window.location.reload(true)
                        }
            console.log(response.data)
            
        } catch (error) {
            
            console.log(error);
            dispatch(setStatus(STATUS.ERROR))
        }
       
    }
}



export function updatesubSubCategory(id,param){

    return async function updatesubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        const headers = {
            'Content-Type': 'application/json', // Assuming your data is JSON
            // Add any other headers here
        };
        try{
            await axios.patch(`http://5.78.109.151:5000/common/subSubCategory/updateSubSubCategories/${id}`,{name:param?.name,subCategoryId:param?.subCategoryId,image:param.image},{headers}).then(res=>{
                if (res.data) {
                    message.success("subSubCategory Updated")
                    window.location.href=window.location.origin+"/#/subSubcategory"
                    
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}

export function updatesubSubCategoryStatus(param){
    return async function updatesubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        const headers = {
            'Content-Type': 'application/json', // Assuming your data is JSON
            // Add any other headers here
        };
        try{
            await axios.patch(`http://5.78.109.151:5000/common/subSubCategory/updateSubSubCategories/${param.id}`,{status:param.status},{headers}).then(res=>{
                if (res.data) {
                    message.success("subSubCategory Updated")
                    window.location.href=window.location.origin+"/#/subSubcategory"
                    
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function deletesubSubCategory(id){
    return async function deletesubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`http://5.78.109.151:5000/common/subSubCategory/delSubSubCategories/${id}`).then(res=>{
                console.log(res.data)
                if(res.data){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdsubSubCategory(id){
    return async function getByIdsubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`http://5.78.109.151:5000/common/subSubCategory/getSubSubCategories/${id}`).then(
                (resp) => {
                    dispatch(getsubSubCategory(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
