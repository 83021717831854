import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const flashSlice=createSlice({
    name: "flash",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setflash(state,action){
            state.data=action.payload
        },
        getflash(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setflash,setStatus,getflash}=flashSlice.actions
export default flashSlice.reducer




// thunk

export function fetchflash(){
    return async function fetchflashThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`http://5.78.109.151:5000/common/voucher/getAllVouchers`).then(
                (resp) => {
                    console.log(resp.data)
                    dispatch(setflash(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createflash(param){
    console.log(param)
    return async function createflashThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try {
            const response = await axios.post('http://5.78.109.151:5000/common/voucher/createVouchers', param, {
                headers: {
                    'Content-Type': 'application/json' 
                },
                
            });
            console.log(response.data)
            if (response.data) {
               await message.success("Coupon created")
                 window.location.reload(true)
                 window.location.href=window.location.origin+"/#/category"

                        }
            console.log(response.data)
            
        } catch (error) {
            
            console.log(error);
            message.error("Something went wrong")
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateflash(id,param){
    return async function updateflashThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`http://5.78.109.151:5000/common/voucher/updateVouchers/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data) {
                    message.success("Coupon Updated")
                    // window.location.reload(true)
                    window.location.href=window.location.origin+"/#/coupons"

                    
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteflash(id){
    return async function deleteflashThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`http://5.78.109.151:5000/common/voucher/delVouchers/${id}`).then(res=>{
                if(res.data){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdflash(id){
   
    return async function getByIdflashThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`http://5.78.109.151:5000/common/voucher/getVouchers/${id}`).then(
                (resp) => {
                
                    dispatch(getflash(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
